import { ICellValue, IColumn, formatDate } from 'react-ui-kit-exante';

import { ForceCloseEventState, type TPostpone } from 'types';
import {
  formatNumberToFinancial,
  resetTablePage,
  transferValueToPercent,
} from 'utils';

import { DATE_TIME_WITH_SECONDS } from './constants';

export type TGetColumnsProps = {
  onFilter: (column: string, value: unknown) => void;
  onRemove: (column: string) => void;
  tableId: string;
};

export const getColumns = ({
  onFilter,
  onRemove,
  tableId,
}: TGetColumnsProps): IColumn<TPostpone> => [
  {
    Header: 'Client Id',
    accessor: 'clientId',
    onFilter: (column: string, value: unknown) => {
      resetTablePage(tableId);
      onFilter(column, value);
    },
    onRemove,
    minWidth: 125,
    maxWidth: 150,
    Cell: ({ row: { original } }: ICellValue<TPostpone>) =>
      original.clientId || '',
  },
  {
    Header: 'Group Id',
    accessor: 'groupId',
    onFilter: (column: string, value: unknown) => {
      resetTablePage(tableId);
      onFilter(column, value);
    },
    onRemove,
    minWidth: 125,
    maxWidth: 150,
    Cell: ({ row: { original } }: ICellValue<TPostpone>) =>
      original.groupId || '',
  },
  {
    Header: 'Comment',
    accessor: 'internalComment',
    disableFilters: true,
    disableSortBy: true,
    tooltip: {
      type: 'custom',
      options: {
        Title: (value: string) => value || '',
      },
    },
    minWidth: 300,
    maxWidth: 325,
    Cell: ({ row: { original } }: ICellValue<TPostpone>) =>
      original.internalComment || '',
  },
  {
    Header: 'Deadline',
    accessor: 'immunityDeadline',
    disableFilters: true,
    tooltip: {
      type: 'custom',
      options: {
        Title: (value: string) =>
          formatDate({
            date: value,
            format: DATE_TIME_WITH_SECONDS,
          }),
      },
    },
    minWidth: 175,
    maxWidth: 200,
    Cell: ({ row: { original } }: ICellValue<TPostpone>) =>
      formatDate({
        date: original.immunityDeadline,
        format: DATE_TIME_WITH_SECONDS,
      }),
  },
  {
    Header: 'Number',
    accessor: 'number',
    disableFilters: true,
    tooltip: {
      type: 'custom',
      options: {
        Title: (value: string) => value || '',
      },
    },
    minWidth: 200,
    maxWidth: 225,
    Cell: ({ row: { original } }: ICellValue<TPostpone>) =>
      original.number || '',
  },
  {
    Header: 'State',
    accessor: 'state',
    disableSortBy: true,
    disableFilters: true,
    minWidth: 75,
    maxWidth: 100,
    Cell: ({ row: { original } }: ICellValue<TPostpone>) =>
      original.state
        ? ForceCloseEventState[
            original.state as keyof typeof ForceCloseEventState
          ]
        : '',
  },
  {
    Header: 'Actual Margin',
    accessor: 'actualMargin',
    disableFilters: true,
    formatting: 'number',
    Cell: ({ row: { original } }: ICellValue<TPostpone>) =>
      formatNumberToFinancial(original.actualMargin),
  },
  {
    Header: 'Actual NAV',
    accessor: 'actualNAV',
    disableFilters: true,
    formatting: 'number',
    Cell: ({ row: { original } }: ICellValue<TPostpone>) =>
      formatNumberToFinancial(original.actualNAV),
  },
  {
    Header: 'Actual MU, %',
    accessor: 'actualMU',
    disableFilters: true,
    formatting: 'number',
    type: 'numberRange',
    Cell: ({ row: { original } }: ICellValue<TPostpone>) => {
      if (Number.isNaN(Number(original.actualMU))) {
        return '';
      }

      return transferValueToPercent(original, 'actualMU');
    },
  },
  {
    Header: 'Actual Rule',
    accessor: 'actualRule',
    disableFilters: true,
    formatting: 'number',
  },
  {
    Header: 'Initial Rule',
    accessor: 'initialRule',
    disableFilters: true,
    formatting: 'number',
  },
  {
    Header: 'Updated At',
    accessor: 'updatedAt',
    disableFilters: true,
    tooltip: {
      type: 'custom',
      options: {
        Title: (value: string) =>
          formatDate({
            date: value,
            format: DATE_TIME_WITH_SECONDS,
          }),
      },
    },
    minWidth: 175,
    maxWidth: 200,
    Cell: ({ row: { original } }: ICellValue<TPostpone>) =>
      formatDate({
        date: original.updatedAt,
        format: DATE_TIME_WITH_SECONDS,
      }),
  },
  {
    Header: 'Inserted At',
    accessor: 'insertedAt',
    disableFilters: true,
    tooltip: {
      type: 'custom',
      options: {
        Title: (value: string) =>
          formatDate({
            date: value,
            format: DATE_TIME_WITH_SECONDS,
          }),
      },
    },
    minWidth: 175,
    maxWidth: 200,
    Cell: ({ row: { original } }: ICellValue<TPostpone>) =>
      formatDate({
        date: original.insertedAt,
        format: DATE_TIME_WITH_SECONDS,
      }),
  },
];
