import { AxiosError } from 'axios';
import { useCallback, useMemo } from 'react';
import { calculateCountOfPages, useTableData } from 'react-ui-kit-exante';

import { postponeService } from 'resources';
import { TParams, TPostpone, TPostponeState } from 'types';

import { RiskEventActions, RiskEventActionsType } from '../../context';
import { getColumns } from '../columns';
import { getAdditionalFilters } from '../filters';

type UsePostponesProps = {
  dispatch: React.Dispatch<RiskEventActionsType>;
  tableId: string;
};

export const usePostpones = ({ dispatch, tableId }: UsePostponesProps) => {
  const getPostpones = useCallback(
    async ({
      params: { page, skip, sorting, ...rest },
    }: {
      params: TParams;
    }) => {
      const updatedParams: TParams = {
        ...rest,
        offset: skip,
        postponed: true,
      };

      dispatch({ type: RiskEventActions.FetchPostponeStart });

      try {
        const data = await postponeService.fetchPostpones(updatedParams);

        dispatch({
          type: RiskEventActions.FetchPostponeSucceed,
        });

        return data;
      } catch (err) {
        dispatch({
          payload: err as AxiosError,
          type: RiskEventActions.FetchPostponeError,
        });

        return {
          postpones: [],
          count: 0,
        };
      }
    },
    [],
  );

  const tableDataArgs = useMemo(
    () => ({
      data: { onFetch: getPostpones },
      tableId,
      saveViewParamsAfterLeave: true,
    }),
    [getPostpones, tableId],
  );

  const {
    data,
    isLoading,
    fetchData: refetch,
    filters,
    limit,
    page,
    removeFilter,
    resetFilters,
    setFilter,
    setLimit,
    setPage,
  } = useTableData<TPostponeState>(tableDataArgs);

  const columns = useMemo(
    () =>
      getColumns({
        onFilter: setFilter,
        onRemove: removeFilter,
        tableId,
      }),
    [removeFilter, setFilter, tableId],
  );

  const additionalFilters = useMemo(
    () =>
      getAdditionalFilters({
        filters,
        onFilter: setFilter,
        onRemove: removeFilter,
        tableId,
      }),
    [filters, removeFilter, setFilter, tableId],
  );

  const filteringProps = useMemo(
    () => ({
      additionalFilters,
      filters,
      manualFilters: true,
      removeAllFilters: resetFilters,
    }),
    [additionalFilters, filters, resetFilters],
  );

  const total = data?.count || 0;
  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );

  const serverPaginationProps = useMemo(
    () => ({
      total,
      setPage,
      pageCount,
      pageSize: limit,
      pageIndex: page,
      setPageSize: setLimit,
    }),
    [limit, page, pageCount, setLimit, setPage, total],
  );

  const handleResolution = useCallback((rowValue: unknown) => {
    const { number } = rowValue as TPostpone;

    dispatch({
      type: RiskEventActions.PostponeResolutionModal,
      payload: {
        number,
        show: true,
      },
    });
  }, []);

  const handleComment = useCallback((rowValue: unknown) => {
    const { number } = rowValue as TPostpone;

    dispatch({
      type: RiskEventActions.PostponeCommentModal,
      payload: {
        number,
        show: true,
      },
    });
  }, []);

  return {
    columns,
    data,
    filteringProps,
    handleComment,
    handleResolution,
    isLoading,
    refetch,
    serverPaginationProps,
  };
};
